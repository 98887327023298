import {HTTP_CLIENT_KEY, IHTTPClient} from "common/api/HTTPClient";
import {Container, IInit} from "common/container/Container";
import {Observable, of} from "rxjs";
import {TestContainerConfig} from "../container";
import {IStatusService} from "../../../common/status/StatusService";
import {STATUS_SERVICE_KEY} from "../../../container/app";
import {emptyList, ItemList} from "../../../common/models/ItemList";
import {Test, TestDTO, toModel} from "../models/Test";
import {Query} from "../../../common/api/Query";
import {catchError, map} from "rxjs/operators";
import {prepareURL} from "../../../common/api/http-helpers";
import { Report, toModelReport } from "../models/Reports";
import { throwError } from 'rxjs'

export interface ITestApi extends IInit {
    getFilteredList(q: Query<Test>): Observable<ItemList<Test>>
    add(e: TestDTO): Observable<Test | undefined>
    update(e: TestDTO): Observable<Test | undefined>
    delete(id: string): Observable<boolean>
    report(id: string): Observable<Report>
}

export class TestApi implements ITestApi {
    private _container!: Container;
    private _httpClient!: IHTTPClient;
    private _url!: string;
    private _statusService!: IStatusService;

    init(c: Container) {
        this._container = c;
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY);
        this._statusService =
            this._container.get<IStatusService>(STATUS_SERVICE_KEY);
        this._url = (this._container.config as TestContainerConfig).moduleFullUrl;
    }

    getFilteredList(q: Query<Test>): Observable<ItemList<Test>> {
        return this._httpClient
            .get<ItemList<Test>>({url: prepareURL(this._url, q)})
            .pipe(
                map<ItemList<TestDTO>, ItemList<Test>>((dto) => {
                    const itemList = emptyList<Test>()
                    itemList.count = dto.count
                    itemList.items = dto.items.map((d) => toModel(d))
                    return itemList
                }),
                catchError((err) => {
                    this._statusService.sendStatus({variant: 'error', error: err})
                    return of(emptyList<Test>())
                })
            )
    }

    add(e: TestDTO): Observable<Test | undefined> {
        return this._httpClient
            .post<Test>({url: this._url, body: e})
            .pipe(
                catchError((err) => {
                    this._statusService.sendStatus({variant: 'error', error: err});
                    return of(undefined)
                })
            )
    }

    update(e: TestDTO): Observable<Test | undefined> {
        return this._httpClient
            .put<Test>({url: this._url, body: e})
            .pipe(
                map<TestDTO, Test>((d) => toModel(d)),
                catchError((err) => {
                    this._statusService.sendStatus({variant: 'error', error: err})
                    return of(undefined)
                })
            )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({url: `${this._url}/${id}`}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return of(false)
            })
        )
    }

    report(id: string): Observable<Report> {
        return this._httpClient.get<Report>({url: `${this._url}/report/${id}`}).pipe(
            catchError((err) => {
                this._statusService.sendStatus({variant: 'error', error: err})
                return throwError(() => err);
            })
        )
    }
}


