import { Box, Container, Grid, Typography,Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {COLOR_CLINICS, COLOR_PRIMARY, COLOR_SECONDARY} from '../../routes/color-constants'
import { useStyles } from './Detail.styles'
import { RouteProps } from '../../routes/AppRouter'
import React, { useEffect, useState } from 'react'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { ClinicDTO, emptyClinicDTO, fromModel } from '../../modules/clinics/models/ClinicDTO'
import { Actions, Field, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import { AppTable } from '../../components/table'
import { PatientReceptiveness, PatientQuery } from '../../modules/patients/models/PatientReceptiveness'
import { BoolQueryParam, Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import { PATIENT_SERVICE_KEY } from '../../modules/patients'
import { PatientService } from '../../modules/patients/services/PatientService'
import { getPatientContainer } from '../../container/patient-module'
import { TITLE_PATIENTS } from '../../routes/title-constants'
import { Title } from '../../components/common/Title'
import { userGenders } from '../../modules/users/enums/GenderType'
import { Permission } from '../../common/enums/Permissions'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { navigate } from '@reach/router'
import { URL_CLINICS, URL_PATIENT } from '../../routes/routes-constants'

type DetailProps = {
  id?: string
}

const goToClinics = () => navigate(URL_CLINICS)
const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<PatientQuery>[],
): QueryParam<PatientQuery>[] | QueryParamN<PatientQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const Detail = (props: DetailProps) => (
  <Container>
    <ClinicDetail {...props} />
    <ClinicPatients {...props} />
  </Container>
)

const isExcluded = (field: keyof ClinicDTO): boolean => ['id', 'testType', 'patients', 'receptiveness','header', 'signature'].includes(field)

export const ClinicDetail = (props: RouteProps) => {
  const { t } = useTranslation()

  const classes = useStyles({ color: COLOR_CLINICS })




  const [clinic, setClinic] = useState<ClinicDTO>(emptyClinicDTO())

  useEffect(() => {
    if (!props.id) {
      return
    }
    clinicService.getByID(props.id).subscribe((res) => {
      if (!res) {
        return
      }
      setClinic(fromModel(res))
    })
  }, [])

  const getValue = (key: string, value: string): string => {
    switch (key) {
      case 'disabled':
        return t(value)
        case 'entryDate':
          const date = new Date(value);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = String(date.getFullYear());
    
          const format = t('dateFormat'); // o "dd/MM/yyyy", dependiendo del idioma
    
          return format
            .replace('MM', month)
            .replace('dd', day)
            .replace('yyyy', year);
      case 'receptiveness':
        return t(value)
      case 'pgd':
        return t(value)
      default:
        return value
    }

  }
  return (
    <Container className={classes.container}>
      <Grid container>
        <Grid item xs={3} alignItems={'stretch'}>
          <Box className={classes.box}>
            <Typography
              align={'left'}
              variant={'h5'}
              style={{ marginLeft: '-23%', marginTop: '2%' }}>
              {clinic.name}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            className={classes.box}
            borderLeft={1}
            style={{ marginTop: '2%', marginBottom: '2%' }}>
            {Object.entries(clinic)
              .filter(([key, _]) => !isExcluded(key as keyof ClinicDTO))
              .filter(([key, _]) => authService.userCan(Permission.changeClinicState) || key !== 'disabled')
              .map(([key, value]) => (
                <Box display='flex' mb={0} p={0} style={{ fontSize: '16px' }}>
                  <Grid className={classes.gridKeys} item xs={5}>
                    <b>{t(key)}</b>:
                  </Grid>
                  <Grid className={classes.gridValues} item xs={7}>
                    <div style={{ backgroundColor: '#f4f4f4' }}>{(value || value === false) && getValue(key, value)}</div>
                  </Grid>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

export const ClinicPatients = (props: RouteProps) => {
  const { t } = useTranslation()
  const classesPrimary = useStyles({ color: COLOR_PRIMARY })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<PatientReceptiveness[]>([])
  const [sort, setSort] = useState<SortParam<PatientQuery>>({
    field: 'firstName',
    desc: true,
  })
  const [searcher, setSearcher] = useState<SearchValue<PatientQuery>[]>([
    {
      name: 'idBioarray',
      label: t('search') + ' ' + t('byIdBioarray'),
    },
    {
      name: 'firstName',
      label: t('search') + ' ' + t('byFirstName'),
    },
    {
      name: 'lastName',
      label: t('search') + ' ' + t('byLastName'),
    },
    {
      name: 'csvDate',
      label: '',
      type: 'date',
    },
  ])

  //use efect que saca la clinica del usuario
  const loggedUser = authService.get()
  const [clinic, setClinic] = useState<ClinicDTO>(emptyClinicDTO())
  useEffect(() => {
    if (!props.id) {
      return
    }
    clinicService.getByID(props.id).subscribe((res) => {
      if (!res) {
        return
      }
      setClinic(fromModel(res))
    })
  }, [])

  useEffect(() => {
    if (!isLoading) {
      return
    }
    patientService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          sort: [{ field: sort.field, desc: sort.desc }],
          query: [
            new QueryParam<PatientQuery>('clinics', [props.id ? props.id : '']),
            new QueryParam<PatientQuery>('pgd', new BoolQueryParam(clinic.activePgd) || false),
            new QueryParam<PatientQuery>('receptiveness', new BoolQueryParam(clinic.activeReceptiveness) || false),
            new QueryParam<PatientQuery>('pgsec',new BoolQueryParam(clinic.activePgsec) || false),
            ...searcherQuery(searcher),
          ],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<PatientReceptiveness>[] = [
    {
      searchable: true,
      sortable: true,
      label: t('idBioarray'),
      name: 'bioarrayCode',
    },
    {
      searchable: true,
      sortable: true,
      label: t('firstName'),
      name: 'firstName',
    },
    {
      searchable: true,
      sortable: true,
      label: t('lastName'),
      name: 'lastName',
    },
    {
      sortable: true,
      label: t('dateOfBirth'),
      name: 'dob',
      renderFunc: (f, i) => {
        if (!i.dob || typeof i.dob !== 'string') return '';
    
    
        const date = new Date(i.dob);
        if (isNaN(date.getTime())) return '';
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
    
       
        const format = t('dateFormat');
    
        return format
            .replace('MM', month)
            .replace('dd', day)
            .replace('yyyy', year);
    }
    
    
    },
    {
      sortable: true,
      label: t('gender'),
      name: 'gender',
      renderFunc: (f, i) => userGenders()[i.gender],
    },
  ]

  const editPatient = (patient: PatientReceptiveness) => navigate(URL_PATIENT.replace(':id', `${patient.id}`))

  const actions: Actions<PatientReceptiveness> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editPatient,
        icon: editIcon,
        label: t('Edit'),
        hidden: () => !authService.userCan(Permission.editPatients),
      },
    ],
  }

  const search: Search<PatientQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<PatientQuery>[]) => {
      const result: SearchValue<PatientQuery>[] = []
      svs.forEach((s) => {
        if (s.type === 'date' && s.value) {
          result.push(Object.assign({ ...s }, { value: new Date(s.value as string).toISOString() }))
        } else {
          result.push(s)
        }
      })
      setSearcher(result)
      setIsLoading(true)
    },
  }

  const sortable: Sort<PatientReceptiveness> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  return (
    <>
      <Title color={COLOR_SECONDARY} title={t(TITLE_PATIENTS)} />
      <AppTable
        styleHeader={{ color: COLOR_PRIMARY }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        search={search}
        sort={sortable}
      />
       <Button
              className={classesPrimary.button}
              variant={'contained'}
              onClick={goToClinics}
              style={{ marginTop: "20px" }}>
              {t('back')}
       </Button>
    </>
  )
}
