import { HTTP_CLIENT_KEY, IHTTPClient } from 'common/api/HTTPClient'
import { Container, IInit } from 'common/container/Container'
import { Observable, of } from 'rxjs'
import { FileContainerConfig } from '../container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import {
    Bedfile,
    BedfileDTO,
    BedfileRange,
    BedfileRangeDTO,
    File,
    FileDTO,
    Panel,
    PanelDTO,
    PanelFront, PanelFrontDTO,
    toModel,
    toModelBedfile,
    toModelPanel, toModelPanelFront,
    toModelRange
} from '../models/File'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'

export interface IFileApi extends IInit {
    getByID(id: string): Observable<File | undefined>

    getByIDInmonucompatibility(id: string): Observable<File | undefined>

    getHeaderByID(id: string): Observable<File | undefined>

    getSignatureByID(id: string): Observable<File | undefined>

    getFilteredList(q: Query<File>): Observable<ItemList<File>>

    getPanelsAndBeds(q: Query<File>): Observable<ItemList<File>>

    add(e: FileDTO): Observable<File | undefined>

    addInm(e: FileDTO): Observable<File | undefined>

    update(e: FileDTO): Observable<File | undefined>

    delete(id: string): Observable<boolean>

    getCurrentScriptByID(id: string): Observable<File | undefined>

    addHeader(e: FileDTO): Observable<File | undefined>

    addSignature(e: FileDTO): Observable<File | undefined>

    uploadPanelFile(e: FileDTO): Observable<File | undefined>

    uploadBedFile(e: FileDTO): Observable<File | undefined>

    getBedfiles(q: Query<File>): Observable<ItemList<any>>

    getBedfileRange(id: string): Observable<BedfileRange | undefined>

    deleteBedfile(id: string): Observable<boolean>

    updateBedfile(e: BedfileDTO): Observable<Bedfile | undefined>

    getPanels(q: Query<Panel>): Observable<ItemList<Panel>>

    getBedfileByID(id: string): Observable<Bedfile | undefined>

    getPanelsNames(q: Query<Panel>): Observable<ItemList<PanelFront>>

    addBAI(e: FileDTO): Observable<File | undefined>

    addBIM(e: FileDTO): Observable<File | undefined>

    getBAI(id: string): Observable<File | undefined>

    getBIM(id: string): Observable<File | undefined>

    getBAIModel(id: string): Observable<File | undefined>

    getBIMModel(id: string): Observable<File | undefined>
}

export class FileApi implements IFileApi {
    private _container!: Container
    private _httpClient!: IHTTPClient
    private _url!: string
    private _statusService!: IStatusService

    init(c: Container) {
        this._container = c
        this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
        this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
        this._url = (this._container.config as FileContainerConfig).moduleFullUrl
    }

    getByID(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getByIDInmonucompatibility(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/inmonucompatibility/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getBedfileByID(id: string): Observable<Bedfile | undefined> {
        return this._httpClient.get<Bedfile>({ url: `${this._url}/bedfile/${id}` }).pipe(
            map<BedfileDTO, Bedfile>((d) => toModelBedfile(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getFilteredList(q: Query<File>): Observable<ItemList<File>> {
        return this._httpClient.get<ItemList<File>>({ url: prepareURL(this._url, q) }).pipe(
            map<ItemList<FileDTO>, ItemList<File>>((dto) => {
                const itemList = emptyList<File>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<File>())
            })
        )
    }

    getPanelsAndBeds(q: Query<File>): Observable<ItemList<File>> {
        return this._httpClient.get<ItemList<File>>({ url: prepareURL(this._url+"/panelsbeds/panelsbeds", q) }).pipe(
            map<ItemList<FileDTO>, ItemList<File>>((dto) => {
                const itemList = emptyList<File>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<File>())
            })
        )
    }

    add(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url, body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    addInm(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url+"/inm", body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    update(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.put<File>({ url: this._url, body: e }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    updateBedfile(e: BedfileDTO): Observable<Bedfile | undefined> {
        return this._httpClient.put<Bedfile>({ url: this._url+"/updatebedfile", body: e }).pipe(
            map<BedfileDTO, Bedfile>((d) => toModelBedfile(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    delete(id: string): Observable<boolean> {
        return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(false)
            })
        )
    }

    deleteBedfile(id: string): Observable<boolean> {
        return this._httpClient.delete({ url: `${this._url}/bedfile/${id}` }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(false)
            })
        )
    }

    getCurrentScriptByID(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/currentscript/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getHeaderByID(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/header/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getSignatureByID(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/signature/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    addHeader(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url + '/header', body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    addSignature(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url + '/signature', body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    uploadPanelFile(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url + "/panels", body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    uploadBedFile(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url + "/bedfile", body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getBedfiles(q: Query<File>): Observable<ItemList<Bedfile>> {
        return this._httpClient.get<ItemList<Bedfile>>({ url: prepareURL(this._url+"/bedfiles/getbedfiles", q) }).pipe(
            map<ItemList<BedfileDTO>, ItemList<Bedfile>>((dto) => {
                const itemList = emptyList<Bedfile>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModelBedfile(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Bedfile>())
            })
        )
    }

    getBedfileRange(id: string): Observable<BedfileRange | undefined> {
        return this._httpClient.get<BedfileRange>({ url: `${this._url}/bedfileRange/${id}` }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getPanels(q: Query<Panel>): Observable<ItemList<Panel>> {
        return this._httpClient.get<ItemList<Panel>>({ url: prepareURL(this._url+"/panels/panels", q) }).pipe(
            map<ItemList<PanelDTO>, ItemList<Panel>>((dto) => {
                const itemList = emptyList<Panel>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModelPanel(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<Panel>())
            })
        )
    }

    getPanelsNames(q: Query<Panel>): Observable<ItemList<PanelFront>> {
        return this._httpClient.get<ItemList<PanelFront>>({ url: prepareURL(this._url+"/panels/panelsName", q) }).pipe(
            map<ItemList<PanelFrontDTO>, ItemList<PanelFront>>((dto) => {
                const itemList = emptyList<PanelFront>()
                itemList.count = dto.count
                itemList.items = dto.items.map((d) => toModelPanelFront(d))
                return itemList
            }),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(emptyList<PanelFront>())
            })
        )
    }

    addBAI(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url + "/createBAI", body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    addBIM(e: FileDTO): Observable<File | undefined> {
        return this._httpClient.post<File>({ url: this._url + "/createBIM", body: e }).pipe(
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getBAI(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/baifile/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getBIM(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/bimfile/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getBAIModel(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/baifileModel/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }

    getBIMModel(id: string): Observable<File | undefined> {
        return this._httpClient.get<File>({ url: `${this._url}/bimfileModel/${id}` }).pipe(
            map<FileDTO, File>((d) => toModel(d)),
            catchError((err) => {
                this._statusService.sendStatus({ variant: 'error', error: err })
                return of(undefined)
            })
        )
    }
}
