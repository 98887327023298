import { useTranslation } from 'react-i18next'
import styles from './Legend.module.css'

export const Legend = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.firstRow}>
        <div className={styles.firstDiv}>
          <div className={styles.colorItem} />
          <div className={styles.textItem}>{t('ado')}</div>
        </div>
        <div className={styles.secondDiv}>
          <div className={styles.colorItem4} />
          <div className={styles.textItem}>{t('incongruity')}</div>
        </div>
     
      </div>

      <div className={styles.row}>
        <div className={styles.firstDiv}>
          <div className={styles.colorItemText}></div>
          <div className={styles.textItem}>{t('affectedAllele')}</div>
        </div>
        <div className={styles.secondDiv}>
          <div className={styles.colorItemText2}></div>
          <div className={styles.textItem}>{t('healthyAllele')}</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.firstDiv}>
          <div className={styles.colorItem3}/>
          <div className={styles.textItem}>{t('semiInfoLegend')}</div>
        </div>

        <div className={styles.secondDiv}>
            <div className={styles.colorItem2} />
            <div className={styles.textItem}>{t('lowqualitycoverage')}</div>
          </div>
      </div>
      <div className={styles.row}>

        <div className={styles.firstDiv}>
          <div className={styles.colorItemText3}>LR</div>
          <div className={styles.textItem}>{t('likelyRecombinant')}</div>
        </div>
      </div>
    </div>
  )
}
