import { COLOR_CLINICS, COLOR_PRIMARY } from '../../routes/color-constants'
import { AppTable, Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Clinic, ClinicQuery } from '../../modules/clinics/models/Clinic'
import { Actions, Pager, Search, SearchValue, Sort } from '../../components/table/types'
import editIcon from '../../assets/table-icons/edit-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import { useTranslation } from 'react-i18next'
import { Query, QueryParam, QueryParamN, SortParam } from '../../common/api/Query'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { navigate } from '@reach/router'
import { URL_CLINIC, URL_CLINIC_SHOW } from '../../routes/routes-constants'
import seeIcon from '../../assets/table-icons/see-icon.svg'
import { Box, Button, Modal } from '@material-ui/core'
import { useStyles } from '../matchings/Matching.styles'
import { Permission } from '../../common/enums/Permissions'
import { getAuthContainer } from '../../container/auth-modules'
import { AuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { FormAction, FormActions } from '../../common/utils/form-generation'
import { commonStyles } from '../../common/styles/Styles'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)

const searcherQuery = (
  svs: SearchValue<ClinicQuery>[],
): QueryParam<ClinicQuery>[] | QueryParamN<ClinicQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))

export const Table = () => {
  const { t } = useTranslation()
  const classes = useStyles({ color: COLOR_PRIMARY })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [selectedClinic, setSelectedClinic] = useState<Clinic | undefined>()
  const [pager, setPager] = useState<Pager>()
  const [page, setPage] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [items, setItems] = useState<Clinic[]>([])
  const [sort, setSort] = useState<SortParam<Clinic>>({
    field: 'name',
    desc: true,
  })

  const loggedUser = authService.get()
  const [searcher, setSearcher] = useState<SearchValue<ClinicQuery>[]>([
    {
      name: 'name',
      label: loggedUser.language === 'es' ? t('search') + ' ' + t('byName') : 'Search by Name',
    },
    {
      name: 'cif',
      label: loggedUser.language === 'es' ? t('search') + ' ' + t('byCIF') : "Search by CIF",
    },
  ])

  const styles = commonStyles()

  useEffect(() => {
    if (!isLoading || !authService.get().clinics.length) {
      return
    }
    const query = [...searcherQuery(searcher)]
    if (!authService.get().permissions.includes(Permission.viewAll)) {
      query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
    }
    clinicService
      .getFilteredList(
        new Query({
          pager: { offset: page * itemsPerPage, limit: itemsPerPage },
          query,
          sort: [{ field: sort.field, desc: sort.desc }],
        }),
      )
      .subscribe((res) => {
        setIsLoading(false)
        setItems(res.items)
        setCount(res.count)
      })
  }, [isLoading])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: itemsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, itemsPerPage])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setItemsPerPage(10)
      return
    }
    setItemsPerPage(Number.parseInt(event.target.value))
  }

  const fields: Field<Clinic>[] = [
    {
      sortable: true,
      searchable: true,
      label: t('name'),
      name: 'name',
    },
    {
      sortable: true,
      searchable: true,
      label: t('address'),
      name: 'address',
    },
    {
      sortable: true,
      searchable: true,
      label: t('cp'),
      name: 'cp',
    },
    {
      sortable: true,
      searchable: true,
      label: t('locality'),
      name: 'locality',
    },
    {
      sortable: true,
      searchable: true,
      label: t('CIF'),
      name: 'cif',
    },
    {
      sortable: true,
      searchable: true,
      label: t('phone'),
      name: 'phone',
    },
  ]

  const viewClinic = (c: Clinic) => navigate(URL_CLINIC_SHOW.replace(':id', `${c.id}`))

  const editClinic = (c: Clinic) => navigate(URL_CLINIC.replace(':id', `${c.id}`))

  const selectClinic = (c: Clinic) => {
    setSelectedClinic(c)
    setOpen(true)
  }

  const deleteClinic = () =>
    selectedClinic?.id && clinicService.delete(selectedClinic.id).subscribe(() => {
      setSelectedClinic(undefined)
      setIsLoading(true)
    })

  const actions: Actions<Clinic> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: viewClinic,
        icon: seeIcon,
        label: t('Show'),
      },
      {
        handler: editClinic,
        icon: editIcon,
        label: t('Edit'),
        hidden: () => !authService.userCan(Permission.editClinics),
      },
     /* {
        handler: selectClinic,
        icon: deleteIcon,
        label: t('Delete'),
        hidden: () => !authService.userCan(Permission.deleteClinics),
      },*/
    ],
  }

  const search: Search<ClinicQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<ClinicQuery>[]) => {
      setSearcher(svs)
      setIsLoading(true)
    },
  }

  const sortable: Sort<Clinic> = {
    name: sort.field,
    direction: sort.desc ? 'desc' : 'asc',
    handleSort: (field) => {
      setSort({ field: field, desc: sort.field === field ? !sort.desc : true })
      setIsLoading(true)
    },
  }

  const createClinic = () => navigate(URL_CLINIC.replace(':id', 'create'))

  const handleDelete = () => {
    setOpen(false)
    deleteClinic()
  }

  const closeModal = () => {
    setOpen(false)
    setSelectedClinic(undefined)
  }

  const actionsDelete: FormAction[] = [
    {
      label: t('close'),
      handleAction: closeModal,
    },
  ]

  return (
    <>
      <Box justifyContent={'space-between'} mb={2}>
        {authService.userCan(Permission.editClinics) && (
          <Button
            className={classes.button}
            variant={'contained'}
            onClick={createClinic}
            style={{ float: 'right' }}>
            {t('create')}
          </Button>
        )}
      </Box>
      <AppTable
        styleHeader={{ color: COLOR_CLINICS }}
        actions={actions}
        fields={fields}
        items={items}
        rowKeyField={'id'}
        pager={pager}
        search={search}
        sort={sortable}
      />
      <Modal open={open}>
        <Box className={styles.modal}>
          <form onSubmit={handleDelete}>
            <Box mt={2} textAlign={'justify'}>
              {t('clinicConfirmationMessage')}
            </Box>
            <FormActions actions={actionsDelete} message={t('confirm')} />
          </form>
        </Box>
      </Modal>
    </>
  )
}
