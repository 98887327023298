
import {RouteProps} from "../../routes/AppRouter";
import {Title} from "../../components/common/Title";
import {Table as PatientsPGSECTable} from "../../features/patients/pgsec/Table";

export const TablePGSEC = (props: RouteProps) => {
    return(
        <>
            <Title color={props.color} title={props.title || ''} />
            <PatientsPGSECTable />
        </>
    )
}