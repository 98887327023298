import {v4 as uuidv4} from 'uuid'
import {File as F} from 'modules/files/models/File'
import {FinalStatus} from "../../individuals/enums/Status";

export interface EmbryoDTO {
    id: string
    embryo: number
    name: string
    embryoGender: string
    fileName: string
    sampleName: string
    date: Date
    finalStatus: FinalStatus
    file: F | undefined
    projectID: string
    seeEmbryo: boolean
    baiFileID: string
    bimFileID: string
    a1Colors: string
    a2Colors: string
}

export interface EmbryosGroup {
    id: string
    name: string
}

export class Embryo {
    private _id: string
    private _embryo: number
    private _name: string
    private _embryoGender: string
    private _fileName: string
    private _sampleName: string
    private _date: Date
    private _finalStatus: FinalStatus
    private _file: F | undefined
    private _projectID: string
    private _seeEmbryo: boolean
    private _baiFileID: string
    private _bimFileID: string
    private _a1Colors: string
    private _a2Colors: string

    constructor(p: EmbryoDTO) {
        this._id = p.id
        this._embryo = p.embryo
        this._name = p.name
        this._embryoGender = p.embryoGender
        this._fileName = p.fileName
        this._sampleName = p.sampleName
        this._date = p.date
        this._finalStatus = p.finalStatus
        this._file = p.file
        this._projectID = p.projectID
        this._seeEmbryo = p.seeEmbryo
        this._baiFileID = p.baiFileID
        this._bimFileID = p.bimFileID
        this._a1Colors = p.a1Colors
        this._a2Colors = p.a2Colors
    }

    get id(): string {
        return this._id
    }

    set id(id: string) {
        this._id = id
    }

    get projectID(): string {
        return this._projectID
    }

    set projectID(projectID: string) {
        this._projectID = projectID
    }

    get embryo(): number {
        return this._embryo
    }

    set embryo(embryo: number) {
        this._embryo = embryo
    }

    get name(): string {
        return this._name
    }

    set name(name: string) {
        this._name = name
    }

    get embryoGender(): string {
        return this._embryoGender
    }

    set embryoGender(embryoGender: string) {
        this._embryoGender = embryoGender
    }

    get fileName(): string {
        return this._fileName
    }

    set fileName(fileName: string) {
        this._fileName = fileName
    }

    get sampleName(): string {
        return this._sampleName
    }

    set sampleName(sampleName: string) {
        this._sampleName = sampleName
    }

    get seeEmbryo(): boolean {
        return this._seeEmbryo
    }

    set seeEmbryo(seeEmbryo: boolean) {
        this._seeEmbryo = seeEmbryo
    }


    get date(): Date {
        return this._date
    }

    set date(date: Date) {
        this._date = date
    }

    get finalStatus(): FinalStatus {
        return this._finalStatus
    }

    set finalStatus(finalStatus: FinalStatus) {
        this._finalStatus = finalStatus
    }

    get file(): F | undefined {
        return this._file
    }

    set file(file: F | undefined) {
        this._file = file
    }

    get baiFileID(): string {
        return this._baiFileID
    }

    set baiFileID(baiFileID: string) {
        this._baiFileID = baiFileID
    }


    get bimFileID(): string {
        return this._bimFileID
    }

    set bimFileID(bimFileID: string) {
        this._bimFileID = bimFileID
    }

    get a1Colors(): string{
        return this._a1Colors
    }
    get a2Colors(): string{
        return this._a2Colors
    }

    toDTO(): EmbryoDTO {
        return {
            id: this.id,
            embryo: this.embryo,
            projectID: this.projectID,
            name: this.name,
            embryoGender: this.embryoGender,
            fileName: this.fileName,
            sampleName: this.sampleName,
            date: this.date,
            finalStatus: this.finalStatus,
            file: this.file,
            seeEmbryo: this.seeEmbryo,
            baiFileID: this.baiFileID,
            bimFileID: this.bimFileID,
            a1Colors: this.a1Colors,
            a2Colors: this.a2Colors
        }
    }
}

export function toModel(d: EmbryoDTO): Embryo {
    return new Embryo(d)
}

export function emptyEmbryoDTO(): EmbryoDTO {
    return {
        id: uuidv4(),
        embryo: 0,
        projectID: '',
        name: '',
        embryoGender: '',
        fileName: '',
        sampleName: '',
        date: new Date(),
        finalStatus: FinalStatus.Null,
        file: undefined,
        seeEmbryo: true,
        baiFileID: '',
        bimFileID: '',
        a1Colors: '',
        a2Colors:'',
    }
}

export interface EmbryoQuery {
}
