import { RouteProps } from '../../routes/AppRouter'
import { Detail as PatientsPGSECDetail } from '../../features/patients/pgsec/Detail'
import { Title } from 'components/common/Title'

export function DetailPGSEC(props: RouteProps) {
  return (
    <>
      <Title color={props.color} title={props.title || ''} />
      <PatientsPGSECDetail id={props.id} />
    </>
  )
}
