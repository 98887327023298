import { RouteProps } from '../../routes/AppRouter'
import { Title } from '../../components/common/Title'
import { TITLE_BEDFILESPG } from '../../routes/title-constants'
import { useTranslation } from 'react-i18next'
import { BedfilesPgForm } from '../../features/scripts/index'

export const BedfilesPg = (props: RouteProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Title title={t(TITLE_BEDFILESPG
      )} color={props.color} />
      <BedfilesPgForm />
    </>
  )
}
