import {GenderType} from "../../users/enums/GenderType";
import {BoolQueryParam} from "../../../common/api/Query";


export interface PatientPGDDTO {
    id: string
    sampleName: string
    bioarrayCode: string
    idPatient: string
    clinicID: string
    clinicName: string
    firstName: string
    lastName: string
    dob?: Date
    creationDate: Date
    gender: GenderType
    genderPartner: GenderType
    dataAboutPatient: string
    medicalRecordNumber: string
    cycleNumber: number
    dataAboutPartner: string
    firstNamePartner: string
    lastNamePartner: string
    dobPartner?: Date
    idPatientPartner: string
    medicalRecordNumberPartner: string
    language: string
    receptiveness: boolean
    pgd: boolean
    pgsec: boolean
    sampleType: string
    dateSampleType: Date
    sampleTypePartner: string
    dateSampleTypePartner: Date
}

export class PatientPGD {
    private _id: string
    private _sampleName: string
    private _bioarrayCode: string
    private _idPatient: string
    private _clinicID: string
    private _clinicName: string
    private _firstName: string
    private _lastName: string
    private _dob?: Date
    private _creationDate: Date
    private _gender: GenderType
    private _genderPartner: GenderType
    private _dataAboutPatient: string
    private _medicalRecordNumber: string
    private _cycleNumber: number
    private _dataAboutPartner: string
    private _firstNamePartner: string
    private _lastNamePartner: string
    private _dobPartner?: Date
    private _idPatientPartner: string
    private _medicalRecordNumberPartner: string
    private _language: string
    private _receptiveness: boolean
    private _pgd: boolean
    private _pgsec: boolean
    private _sampleType: string
    private _dateSampleType: Date
    private _sampleTypePartner: string
    private _dateSampleTypePartner: Date



    constructor(p: PatientPGDDTO) {
        this._id = p.id
        this._sampleName = p.sampleName
        this._bioarrayCode = p.bioarrayCode
        this._idPatient = p.idPatient
        this._clinicID = p.clinicID
        this._clinicName = p.clinicName
        this._firstName = p.firstName
        this._lastName = p.lastName
        this._dob = p.dob
        this._creationDate = p.creationDate
        this._gender = p.gender
        this._genderPartner = p.genderPartner
        this._dataAboutPatient = p.dataAboutPatient
        this._medicalRecordNumber = p.medicalRecordNumber
        this._cycleNumber = p.cycleNumber
        this._dataAboutPartner = p.dataAboutPartner
        this._firstNamePartner = p.firstNamePartner
        this._lastNamePartner = p.lastNamePartner
        this._dobPartner = p.dobPartner
        this._idPatientPartner = p.idPatientPartner
        this._medicalRecordNumberPartner = p.medicalRecordNumberPartner
        this._language = p.language
        this._receptiveness = p.receptiveness
        this._pgd = p.pgd
        this._pgsec = p.pgsec
        this._sampleType = p.sampleType
        this._dateSampleType = p.dateSampleType
        this._sampleTypePartner = p.sampleTypePartner
        this._dateSampleTypePartner = p.dateSampleTypePartner
    }

    get id(): string {
        return this._id
    }

    set id(value: string) {
        this._id = value
    }

    get bioarrayCode(): string {
        return this._bioarrayCode
    }

    set bioarrayCode(value: string) {
        this._bioarrayCode = value
    }

    get idPatient(): string {
        return this._idPatient
    }

    set idPatient(value: string) {
        this._idPatient = value
    }

    get firstName(): string {
        return this._firstName
    }

    set firstName(value: string) {
        this._firstName = value
    }

    get lastName(): string {
        return this._lastName
    }

    set lastName(value: string) {
        this._lastName = value
    }

    get fullName(): string {
        return this._firstName + ' ' + this._lastName
    }

    get dob(): Date | undefined {
        return this._dob
    }

    set dob(value: Date | undefined) {
        this._dob = value
    }

    get creationDate(): Date {
        return this._creationDate;
    }

    set creationDate(value: Date) {
        this._creationDate = value;
    }

    get gender(): GenderType {
        return this._gender
    }

    set gender(value: GenderType) {
        this._gender = value
    }

    get genderPartner(): GenderType {
        return this._genderPartner
    }

    set genderPartner(value: GenderType) {
        this._genderPartner = value
    }

    get sampleName(): string {
        return this._sampleName
    }

    set sampleName(value: string) {
        this._sampleName = value
    }

    get clinicID(): string {
        return this._clinicID
    }

    set clinicID(value: string) {
        this._clinicID = value
    }
    get clinicName(): string {
        return this._clinicName
    }

    set clinicName(value: string) {
        this._clinicName = value
    }
    get dataAboutPatient(): string {
        return this._dataAboutPatient
    }

    set dataAboutPatient(value: string) {
        this._dataAboutPatient = value
    }

    get medicalRecordNumber(): string {
        return this._medicalRecordNumber
    }

    set medicalRecordNumber(value: string) {
        this._medicalRecordNumber = value
    }

    get cycleNumber(): number {
        return this._cycleNumber
    }

    set cycleNumber(value: number) {
        this._cycleNumber = value
    }

    get dataAboutPartner(): string {
        return this._dataAboutPartner
    }

    set dataAboutPartner(value: string) {
        this._dataAboutPartner = value
    }

    get firstNamePartner(): string {
        return this._firstNamePartner
    }

    set firstNamePartner(value: string) {
        this._firstNamePartner = value
    }
    get lastNamePartner(): string {
        return this._lastNamePartner
    }

    set lastNamePartner(value: string) {
        this._lastNamePartner = value
    }

    get dobPartner(): Date | undefined {
        return this._dobPartner
    }

    set dobPartner(value: Date | undefined) {
        this._dobPartner = value
    }

    get idPatientPartner(): string {
        return this._idPatientPartner
    }

    set idPatientPartner(value: string) {
        this._idPatientPartner = value
    }

    get medicalRecordNumberPartner(): string {
        return this._medicalRecordNumberPartner
    }

    set medicalRecordNumberPartner(value: string) {
        this._medicalRecordNumberPartner = value
    }

    get language(): string {
        return this._language
    }

    set language(value: string) {
        this._language = value
    }

    get receptiveness(): boolean {
        return this._receptiveness
    }

    set receptiveness(value: boolean) {
        this._receptiveness = value
    }

    get pgd(): boolean {
        return this._pgd
    }
    
    get pgsec(): boolean {
        return this._pgsec
    }

    set pgd(value: boolean) {
        this._pgd = value
    }

    get sampleType(): string {
        return this._sampleType
    }

    set sampleType(value: string) {
        this._sampleType = value
    }

    get dateSampleType(): Date {
        return this._dateSampleType
    }

    set dateSampleType(value: Date) {
        this._dateSampleType = value
    }

    get sampleTypePartner(): string {
        return this._sampleTypePartner
    }

    set sampleTypePartner(value: string) {
        this._sampleTypePartner = value
    }

    get dateSampleTypePartner(): Date {
        return this._dateSampleTypePartner
    }

    set dateSampleTypePartner(value: Date) {
        this._dateSampleTypePartner = value
    }


    toDTO(): PatientPGDDTO {
        return {
            id: this.id,
            sampleName: this.sampleName,
            bioarrayCode: this.bioarrayCode,
            idPatient: this.idPatient,
            clinicID: this.clinicID,
            clinicName: this.clinicName,
            firstName: this.firstName,
            lastName: this.lastName,
            dob: this.dob,
            creationDate: this.creationDate,
            gender: this.gender,
            genderPartner: this.genderPartner,
            dataAboutPatient: this.dataAboutPatient,
            medicalRecordNumber: this.medicalRecordNumber,
            cycleNumber: this.cycleNumber,
            dataAboutPartner: this.dataAboutPartner,
            firstNamePartner: this.firstNamePartner,
            lastNamePartner: this.lastNamePartner,
            dobPartner: this.dobPartner,
            idPatientPartner: this.idPatientPartner,
            medicalRecordNumberPartner: this.medicalRecordNumberPartner,
            language: this.language,
            receptiveness: this.receptiveness,
            pgd: this.pgd,
            pgsec: this.pgsec,
            sampleType: this.sampleType,
            dateSampleType: this.dateSampleType,
            sampleTypePartner: this.sampleTypePartner,
            dateSampleTypePartner: this.dateSampleTypePartner,
        }
    }
}

export function toModelPGD(d: PatientPGDDTO): PatientPGD {
    return new PatientPGD(d)
}

export function emptyPatientPGDDTO(): PatientPGDDTO {
    return {
        id: '',
        sampleName: '',
        bioarrayCode: '',
        idPatient: '',
        clinicID: '',
        clinicName: '',
        firstName: '',
        lastName: '',
        dob: undefined,
        creationDate: new Date(),
        gender: GenderType.Female,
        genderPartner: GenderType.Female,
        dataAboutPatient: '',
        medicalRecordNumber: '',
        cycleNumber: 0,
        dataAboutPartner: '',
        firstNamePartner: '',
        lastNamePartner: '',
        dobPartner: undefined,
        idPatientPartner: '',
        medicalRecordNumberPartner: '',
        language: '',
        receptiveness: false,
        pgd: true,
        pgsec: false,
        sampleType: '',
        dateSampleType: new Date(),
        sampleTypePartner: '',
        dateSampleTypePartner: new Date(),
    }
}
export function emptyPatientPGSECDTO(): PatientPGDDTO {
    return {
        id: '',
        sampleName: '',
        bioarrayCode: '',
        idPatient: '',
        clinicID: '',
        clinicName: '',
        firstName: '',
        lastName: '',
        dob: undefined,
        creationDate: new Date(),
        gender: GenderType.Female,
        genderPartner: GenderType.Female,
        dataAboutPatient: '',
        medicalRecordNumber: '',
        cycleNumber: 0,
        dataAboutPartner: '',
        firstNamePartner: '',
        lastNamePartner: '',
        dobPartner: undefined,
        idPatientPartner: '',
        medicalRecordNumberPartner: '',
        language: '',
        receptiveness: false,
        pgd: false,
        pgsec: true,
        sampleType: '',
        dateSampleType: new Date(),
        sampleTypePartner: '',
        dateSampleTypePartner: new Date(),
    }
}

export interface PatientQuery {
    clinics: string[]
    ids: string
    idPatient: string
    sampleName: string
    idBioarray: string
    clinicName: string
    firstName: string
    lastName: string
    receptiveness: BoolQueryParam
    pgd: BoolQueryParam
    pgsec: BoolQueryParam
}