import {getAuthContainer} from 'container/auth-modules'
import {AUTH_SERVICE_KEY} from 'modules/auth'
import {IAuthService} from 'modules/auth/services/AuthService'
import {v4 as uuidv4} from 'uuid'
import {ChildGenderPGD} from "../../users/enums/GenderType";

const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export interface ProjectDTO {
    id: string
    name: string
    userID: string
    bedfileID: string
    nameHer: string
    dobHer: Date
    nameHim: string
    dobHim: Date
    panelID: string
    pathology: string
    receptionDate: Date
    reportDate: Date
    childGender: ChildGenderPGD
    embryoNumber: number
    nifHer: string
    nifHim: string
    nhchHer: string
    nhchHim: string
    creationDate: Date
    status: boolean
    patientID: string
    informativityID: string
    resultTableID: string
    embryosGroupID: string
    infoAnalizedBy: string
    infoValidateBy: string
    embryoAnalizedBy: string
    embryoValidateBy: string
    mutationPosition: string
    firstGene:string
    lastGene: string   
    firstSNP:string
    lastSNP: string   
    chr: string
}

export interface ProjectAnalysis {
    id: string
    name: string
    userID: string
    bedfileID: string
    nameHer: string
    dobHer: Date
    nameHim: string
    dobHim: Date
    panelID: string
    pathology: string
    receptionDate: Date
    reportDate: Date
    childGender: ChildGenderPGD
    embryoNumber: number
    nifHer: string
    nifHim: string
    nhchHer: string
    nhchHim: string
    creationDate: Date
    status: boolean
    patientID: string
    informativityID: string
    resultTableID: string
    embryosGroupID: string
    activeReceptiveness: boolean
    activePgd: boolean
    activePgsec: boolean
    infoAnalizedBy: string
    infoValidateBy: string
    embryoAnalizedBy: string
    embryoValidateBy: string
    mutationPosition: string
    baiFileID: string
    bimFileID: string
    firstGene: string
    lastGene: string
    firstSNP:string
    lastSNP: string   
    chr: string

}

export class Project {
    private _id: string
    private _name: string
    private _userID: string
    private _bedfileID: string
    private _nameHer: string
    private _dobHer: Date
    private _nameHim: string
    private _dobHim: Date
    private _panelID: string
    private _pathology: string
    private _receptionDate: Date
    private _reportDate: Date
    private _childGender: ChildGenderPGD
    private _embryoNumber: number
    private _nifHer: string
    private _nifHim: string
    private _nhchHer: string
    private _nhchHim: string
    private _creationDate: Date
    private _status: boolean
    private _patientID: string
    private _informativityID: string
    private _resultTableID: string
    private _embryosGroupID: string
    private _infoAnalizedBy: string
    private _infoValidateBy: string
    private _embryoAnalizedBy: string
    private _embryoValidateBy: string
    private _mutationPosition: string
    private _firstGene: string
    private _lastGene:string 
    private _firstSNP: string
    private _lastSNP: string 
    private _chr: string

    constructor(p: ProjectDTO) {
        this._id = p.id
        this._name = p.name
        this._userID = p.userID
        this._bedfileID = p.bedfileID
        this._nameHer = p.nameHer
        this._dobHer = p.dobHer
        this._nameHim = p.nameHim
        this._dobHim = p.dobHim
        this._panelID = p.panelID
        this._pathology = p.pathology
        this._receptionDate = p.receptionDate
        this._reportDate = p.reportDate
        this._childGender = p.childGender
        this._embryoNumber = p.embryoNumber
        this._nifHer = p.nifHer
        this._nifHim = p.nifHim
        this._nhchHer = p.nhchHer
        this._nhchHim = p.nhchHim
        this._creationDate = p.creationDate
        this._status = p.status
        this._patientID = p.patientID
        this._informativityID = p.informativityID
        this._resultTableID = p.resultTableID
        this._embryosGroupID = p.embryosGroupID
        this._infoAnalizedBy = p.infoAnalizedBy
        this._infoValidateBy = p.infoValidateBy
        this._embryoAnalizedBy = p.embryoAnalizedBy
        this._embryoValidateBy = p.embryoValidateBy
        this._mutationPosition = p.mutationPosition
        this._firstGene = p.firstGene 
        this._lastGene = p.lastGene 
        this._firstSNP = p.firstSNP 
        this._lastSNP = p.lastSNP 
        this._chr = p.chr
    }

    get firstGene(): string {
        return this._firstGene
    }

    get lastGene(): string {
        return this._lastGene
    }



    get id(): string {
        return this._id
    }

    get embryosGroupID(): string {
        return this._embryosGroupID
    }

    get informativityID(): string {
        return this._informativityID
    }

    get name(): string {
        return this._name
    }

    get patientID(): string {
        return this._patientID
    }

    get userID(): string {
        return this._userID
    }

    get bedfileID(): string {
        return this._bedfileID
    }

    get nameHer(): string {
        return this._nameHer
    }

    get dobHer(): Date {
        return this._dobHer
    }

    get nameHim(): string {
        return this._nameHim
    }

    get dobHim(): Date {
        return this._dobHim
    }

    get panelID(): string {
        return this._panelID
    }

    get pathology(): string {
        return this._pathology
    }

    get receptionDate(): Date {
        return this._receptionDate
    }

    get reportDate(): Date {
        return this._reportDate
    }

    get childGender(): ChildGenderPGD {
        return this._childGender
    }

    get embryoNumber(): number {
        return this._embryoNumber
    }

    get nifHer(): string {
        return this._nifHer
    }

    get nifHim(): string {
        return this._nifHim
    }

    get nhchHer(): string {
        return this._nhchHer
    }

    get nhchHim(): string {
        return this._nhchHim
    }

    get creationDate(): Date {
        return this._creationDate
    }

    get status(): boolean {
        return this._status
    }

    get infoAnalizedBy(): string {
        return this._infoAnalizedBy
    }

    get infoValidateBy(): string {
        return this._infoValidateBy
    }

    get embryoAnalizedBy(): string {
        return this._embryoAnalizedBy
    }

    get embryoValidateBy(): string {
        return this._embryoValidateBy
    }

    get mutationPosition(): string {
        return this._mutationPosition
    }

    get resultTableID(): string {
        return this._resultTableID
    }

    get firstSNP(): string {
        return this._firstSNP
    }

    get lastSNP(): string {
        return this._lastSNP
    }

    get chr(): string {
        return this._chr
    }


    toDTO(): ProjectDTO {
        return {
            id: this.id,
            name: this.name,
            userID: this.userID,
            bedfileID: this.bedfileID,
            nameHer: this.nameHer,
            dobHer: this.dobHer,
            nameHim: this.nameHim,
            dobHim: this.dobHim,
            panelID: this.panelID,
            pathology: this.pathology,
            receptionDate: this.receptionDate,
            reportDate: this.reportDate,
            childGender: this.childGender,
            embryoNumber: this.embryoNumber,
            nifHer: this.nifHer,
            nifHim: this.nifHim,
            nhchHer: this.nhchHer,
            nhchHim: this.nhchHim,
            creationDate: this.creationDate,
            status: this.status,
            patientID: this.patientID,
            informativityID: this.informativityID,
            resultTableID: this.resultTableID,
            embryosGroupID: this.embryosGroupID,
            infoAnalizedBy: this.infoAnalizedBy,
            infoValidateBy: this.infoValidateBy,
            embryoAnalizedBy: this.embryoAnalizedBy,
            embryoValidateBy: this.embryoValidateBy,
            mutationPosition: this.mutationPosition,
            firstGene: this.firstGene,
            lastGene: this.lastGene,
            firstSNP: this.firstSNP,
            lastSNP: this.lastSNP,
            chr: this.chr
        }
    }
}

export function toModel(d: ProjectDTO): Project {
    return new Project(d)
}

export function emptyProjectDTO(patientID: string): ProjectDTO {
    return {
        id: uuidv4(),
        name: '',
        userID: authService.get().id,
        bedfileID: '',
        nameHer: '',
        dobHer: new Date(),
        nameHim: '',
        dobHim: new Date(),
        panelID: '',
        pathology: '',
        receptionDate: new Date(),
        reportDate: new Date(),
        childGender: ChildGenderPGD.Unknown,
        embryoNumber: 0,
        nifHer: '',
        nifHim: '',
        nhchHer: '',
        nhchHim: '',
        creationDate: new Date(),
        status: true,
        patientID: patientID,
        informativityID: uuidv4(),
        resultTableID: uuidv4(),
        embryosGroupID: uuidv4(),
        infoAnalizedBy: '',
        infoValidateBy: '',
        embryoAnalizedBy: '',
        embryoValidateBy: '',
        mutationPosition: '',
        firstGene: '',
        lastGene: '',
        firstSNP: '',
        lastSNP: '',
        chr: ''
    }
}

export interface ProjectQuery {
    id: string
    name: string
    nameHer: string
    nameHim: string
    infoAnalizedBy: string
    infoValidateBy: string
    embryoAnalizedBy: string
    embryoValidateBy: string
    pgType : string
    patientID: string
}


export function convertToProjectDTO(analysis: ProjectAnalysis): ProjectDTO {
    return {
        id: analysis.id,
        name: analysis.name,
        userID: analysis.userID,
        bedfileID: analysis.bedfileID,
        nameHer: analysis.nameHer,
        dobHer: analysis.dobHer,
        nameHim: analysis.nameHim,
        dobHim: analysis.dobHim,
        panelID: analysis.panelID,
        pathology: analysis.pathology,
        receptionDate: analysis.receptionDate,
        reportDate: analysis.reportDate,
        childGender: analysis.childGender,
        embryoNumber: analysis.embryoNumber,
        nifHer: analysis.nifHer,
        nifHim: analysis.nifHim,
        nhchHer: analysis.nhchHer,
        nhchHim: analysis.nhchHim,
        creationDate: analysis.creationDate,
        status: analysis.status,
        patientID: analysis.patientID,
        informativityID: analysis.informativityID,
        resultTableID: analysis.resultTableID,
        embryosGroupID: analysis.embryosGroupID,
        infoAnalizedBy: analysis.infoAnalizedBy,
        infoValidateBy: analysis.infoValidateBy,
        embryoAnalizedBy: analysis.embryoAnalizedBy,
        embryoValidateBy: analysis.embryoValidateBy,
        mutationPosition: analysis.mutationPosition,
        firstGene: analysis.firstGene,
        lastGene: analysis.lastGene,
        firstSNP: analysis.firstSNP,
        lastSNP: analysis.lastSNP,
        chr: analysis.chr
    };
}