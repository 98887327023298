import {Box, Button, Container, Grid, InputLabel, MenuItem, Select, Typography} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {COLOR_CLINICS, COLOR_PRIMARY, COLOR_SECONDARY} from '../../routes/color-constants'
import {useStyles} from './Detail.styles'
import {RouteProps} from '../../routes/AppRouter'
import React, {useEffect, useState} from 'react'
import {Actions, Field, Pager, Sort} from '../../components/table/types'
import {AppTable} from '../../components/table'
import {Query, QueryParam, SortParam} from '../../common/api/Query'
import {emptyPatientReceptivenessDTO, PatientReceptivenessDTO} from '../../modules/patients/models/PatientReceptiveness'
import {PatientService} from '../../modules/patients/services/PatientService'
import {PATIENT_SERVICE_KEY} from '../../modules/patients'
import {getPatientContainer} from '../../container/patient-module'
import {TITLE_TESTS} from '../../routes/title-constants'
import {Title} from '../../components/common/Title'
import {getTestContainer} from '../../container/test-module'
import {TestService} from '../../modules/tests/services/TestService'
import {TEST_SERVICE_KEY} from '../../modules/tests'
import {Test} from '../../modules/tests/models/Test'
import downloadIcon from '../../assets/table-icons/download-icon.svg'
import deleteIcon from '../../assets/table-icons/delete-icon.svg'
import {GenderType, userGenders} from '../../modules/users/enums/GenderType'
import {Permission} from '../../common/enums/Permissions'
import {getAuthContainer} from '../../container/auth-modules'
import {AuthService} from '../../modules/auth/services/AuthService'
import {AUTH_SERVICE_KEY} from '../../modules/auth'
import {downloadFile} from '../../common/files/file'
import {getFileContainer} from '../../container/file-module'
import {FileService} from '../../modules/files/services/FileService'
import {FILE_SERVICE_KEY} from '../../modules/files'
import {testCycles} from '../../modules/tests/enums/TestCycle'
import {getReceptivenessFileContainer} from '../../container/receptiveness-files-module'
import {ReceptivenessFileService} from '../../modules/receptiveness-files/services/ReceptivenessFileService'
import {RECEPTIVENESS_FILE_SERVICE_KEY} from '../../modules/receptiveness-files/container'
import { ArrowBack } from '@material-ui/icons'
import { URL_PATIENTS, URL_PATIENTS_PGD } from 'routes/routes-constants'
import { navigate } from '@reach/router'

type DetailProps = {
    id?: string
}

const authService = getAuthContainer().get<AuthService>(AUTH_SERVICE_KEY)
const testService = getTestContainer().get<TestService>(TEST_SERVICE_KEY)
const patientService = getPatientContainer().get<PatientService>(PATIENT_SERVICE_KEY)
const fileService = getFileContainer().get<FileService>(FILE_SERVICE_KEY)
const receptivenessFileService = getReceptivenessFileContainer().get<ReceptivenessFileService>(RECEPTIVENESS_FILE_SERVICE_KEY)

export const Detail = (props: DetailProps) => {
    return (
        <Container>
            <PatientDetail {...props} />
            <PatientTests {...props} />
        </Container>
    )
}

const isExcluded = (field: string): boolean => {
    const fields = [
        'id',
        'patientParams',
        'matchings',
        'ethnicity',
        'eyeColor',
        'skinColor',
        'hairColor',
        'bioarrayCode',
        'clinicID',
        'type',
        'height',
        'isPublic',
        'csvDate'
    ]

    return fields.includes(field)
}

export const PatientDetail = (props: RouteProps) => {
    const {t} = useTranslation()

    const classes = useStyles({color: COLOR_CLINICS})

    const [patient, setPatient] = useState<PatientReceptivenessDTO>(emptyPatientReceptivenessDTO())

    useEffect(() => {
        if (!props.id) {
            return
        }
        patientService.getByID(props.id).subscribe((res) => {
            if (res) {
                setPatient(res.toDTO())
            }
        })
    }, [])

    const getValue = (key: string, value: string): string => {
        switch (key) {
            case 'gender':
                return t(userGenders()[value as unknown as GenderType])
            case 'isPublic':
                return t(value)
            case 'creationDate':
                const date = new Date(value);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear());
          
                const format = t('dateFormat'); 
          
                return format
                  .replace('MM', month)
                  .replace('dd', day)
                  .replace('yyyy', year);    

               
            case 'dob':
                const dateat = new Date(value);
                const dayat = String(dateat.getDate()).padStart(2, '0');
                const monthat = String(dateat.getMonth() + 1).padStart(2, '0');
                const yearat = String(dateat.getFullYear());
          
                const formatat = t('dateFormat'); 
          
                return formatat
                  .replace('MM', monthat)
                  .replace('dd', dayat)
                  .replace('yyyy', yearat);
            default:
                return value
        }
    }

    return (
        <Container className={classes.container}>
            <Grid container>
                <Grid item xs={3} alignItems={'stretch'}>
                    <Box className={classes.box}>
                        <Typography align={'left'} variant={'h5'} className={classes.fullName}>
                            {patient.firstName + ' ' + patient.lastName}
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={9}>
                    <Box className={classes.box} borderLeft={1}>
                        {Object.entries(patient)
                            .filter(([key, _]) => !isExcluded(key))
                            .map(([key, value]) => (
                                <Box display='flex' mb={0} p={0}>
                                    <b>{t(key)}</b>: {(value || value === false) && getValue(key, value)}
                                </Box>
                            ))}
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export const PatientTests = (props: RouteProps) => {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [pager, setPager] = useState<Pager>()
    const [page, setPage] = useState<number>(0)
    const [count, setCount] = useState<number>(0)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const [items, setItems] = useState<Test[]>([])
    const [type, setType] = useState<string>('receptivness')

    const [sort, setSort] = useState<SortParam<Test>>({
        field: 'createdAt',
        desc: true,
    })
    const options = [
        { value: 'receptivness', label: t('receptiveness') },
        { value: 'immunocom', label: t('inmunocompatibility') },
      ]


    useEffect(() => {
        if (!isLoading) {
            return
        }
        testService
            .getFilteredList(
                new Query({
                    query: [new QueryParam<Test>('patientID', props.id || '')],
                    pager: {offset: page * itemsPerPage, limit: itemsPerPage},
                    sort: [{field: sort.field, desc: sort.desc}],
                }),
            )
            .subscribe((res) => {
                setIsLoading(false)
                setItems(res.items)
                setCount(res.count)
            })
    }, [isLoading])

    useEffect(() => {
        setIsLoading(true)
        setPager({
            page,
            count,
            handleChangePage: handlePaginationChange,
            rowsPerPage: itemsPerPage,
            handleChangeRowsPerPage,
        })
    }, [page, count, itemsPerPage])

    const handlePaginationChange = (event: unknown, value: number) => setPage(value)

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number.isNaN(event.target.value)) {
            setItemsPerPage(10)
            return
        }
        setItemsPerPage(Number.parseInt(event.target.value))
    }

    const fields: Field<Test>[] = [
        
            {
                sortable: true,
                label: t('createdAt'),
                name: 'createdAt',
                renderFunc: (f, i) => {
                    const date = new Date(i.createdAt);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = String(date.getFullYear()); // Convertir a string
                
                    // Suponiendo que tienes el formato en una variable
                    const format = t('dateFormat'); // o "dd/MM/yyyy", dependiendo del idioma
                
                    return format
                        .replace('MM', month)
                        .replace('dd', day)
                        .replace('yyyy', year);
                } 
            },
            {
                sortable: true,
                label: t('cycleType'),
                name: 'cycle',
                renderFunc: (f, i) => testCycles()[i.cycle],
            },
            {
                sortable: true,
                label: t('progesteroneDate'),
                name: 'progesteroneDate',
                renderFunc: (f, i) => i.progesteroneDate ? new Date(i.progesteroneDate).toLocaleString() : '',
            },
            {
                sortable: true,
                label: t('biopsyDate'),
                name: 'biopsyDate',
                renderFunc: (f, i) => i.biopsyDate ? new Date(i.biopsyDate).toLocaleString() : '',
            },
            {
                sortable: true,
                label: t('version'),
                name: 'algorithmVersion',
                renderFunc: (f, i) => '' + (i.algorithmVersion ?? ''),
            },
            {
                label: t('result'),
                name: 'result',
                renderFunc: (f, i) => i.result || '',
            }
        ]
    
        const immunoFields: Field<Test>[] = [
        
            {
                sortable: true,
                label: t('createdAt'),
                name: 'createdAt',
                renderFunc: (f, i) => {
                    const date = new Date(i.createdAt);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const year = String(date.getFullYear()); // Convertir a string
                
                    // Suponiendo que tienes el formato en una variable
                    const format = t('dateFormat'); // o "dd/MM/yyyy", dependiendo del idioma
                
                    return format
                        .replace('MM', month)
                        .replace('dd', day)
                        .replace('yyyy', year);
                } 
            },
            {
                sortable: true,
                label: t('KIRresult'),
                name: 'cycle',
                renderFunc: (f, i) => testCycles()[i.cycle],
            },
            {
                sortable: true,
                label: t('HLA-Cresult'),
                name: 'progesteroneDate',
                renderFunc: (f, i) => i.progesteroneDate ? new Date(i.progesteroneDate).toLocaleString() : '',
            },
            {
                sortable: true,
                label: t('RhDresult'),
                name: 'biopsyDate',
                renderFunc: (f, i) => i.biopsyDate ? new Date(i.biopsyDate).toLocaleString() : '',
            },
            {
                sortable: true,
                label: t('version'),
                name: 'algorithmVersion',
                renderFunc: (f, i) => '' + (i.algorithmVersion ?? ''),
            },
            {
                label: t('ABOresult'),
                name: 'result',
                renderFunc: (f, i) => i.result || '',
            }
        ]

   


    const downloadPDF = (t: Test) => t.pdf && receptivenessFileService.getByID(t.pdf).subscribe((res) =>
        res && downloadFile(res.name, res.mimeType, res.data))

    const showDownloadPDF = (t: Test): boolean => !t.pdf

    const deleteTest = (t: Test) => testService.delete(t.id).subscribe(() => setIsLoading(true))

    const showDeleteButton = () => !authService.userCan(Permission.deletePatientTests)

    const actions: Actions<Test> = {
        actionsColumn: t('Actions'),
        items: [
          /*  {
                handler: deleteTest,
                hidden: showDeleteButton,
                icon: deleteIcon,
                label: t('Delete'),
            },*/
            {
                handler: downloadPDF,
                hidden: showDownloadPDF,
                icon: downloadIcon,
                label: t('Download'),
            },
        ],
    }

    const sortable: Sort<Test> = {
        name: sort.field,
        direction: sort.desc ? 'desc' : 'asc',
        handleSort: (field) => {
            setSort({field: field, desc: sort.field === field ? !sort.desc : true})
            setIsLoading(true)
        },
    }
  const classes = useStyles({ color: COLOR_PRIMARY })

    return (
        <>
            <Title color={COLOR_SECONDARY} title={t("analysis")}/>
            <Grid item xs={3} className={classes.scriptSelectGrid} >
                <Select
                    fullWidth
                    value={type}
                    className={classes.scriptSelect}
                    onChange={(e) => e && typeof e.target.value === 'string' && setType(e.target.value)}>
                    {options.map((l) => (
                    <MenuItem value={l.value} key={l.value}>
                        {l.label}
                    </MenuItem>
                    ))}
                </Select>
            </Grid>{
                type === 'receptivness' ? (
            <AppTable
                styleHeader={{color: COLOR_PRIMARY}}
                actions={actions}
                fields={fields}
                items={items}
                rowKeyField={'testTypeID'}
                pager={pager}
                sort={sortable}
            />) : (
            <AppTable
                styleHeader={{color: COLOR_PRIMARY}}
                actions={actions}
                fields={immunoFields}
                items={items}
                rowKeyField={'testTypeID'}
                pager={pager}
                sort={sortable}
            />)
            }
                 <Button
              className={classes.button}
              variant={'contained'}
              onClick={() => navigate(URL_PATIENTS)}
              style={{ marginTop: "20px" }}>
              {t('back')}
       </Button>
        </>
    )
}


              