import { LoggedUserDTO } from './LoggedUserDTO'

export type LoggedUserProps = {
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    sessionExpires: Date
    language: string
    clinics: string[]
    permissions: string[]
}

export class LoggedUser {
    private _id: string
    private readonly _firstName: string
    private readonly _lastName: string
    private readonly _email: string
    private readonly _phone: string
    private readonly _language: string
    private readonly _clinics: string[]
    private _sessionExpires: Date
    private readonly _permissions: string[]

    constructor(p: LoggedUserProps) {
        this._id = p.id
        this._firstName = p.firstName || ''
        this._lastName = p.lastName || ''
        this._email = p.email || ''
        this._phone = p.phone || ''
        this._language = p.language || 'es'
        this._clinics = p.clinics
        this._sessionExpires = new Date(p.sessionExpires)
        this._permissions = p.permissions
    }

    get id(): string {
        return this._id
    }

    set id(value: string) {
        this._id = value
    }

    get firstName(): string {
        return this._firstName
    }

    get lastName(): string {
        return this._lastName
    }

    get email(): string {
        return this._email
    }

    get phone(): string {
        return this._phone
    }

    get sessionExpires(): Date {
        return this._sessionExpires
    }

    set sessionExpires(s: Date) {
        this._sessionExpires = s
      }
    

    get language(): string {
        return this._language
    }

    get clinics(): string[] {
        return this._clinics
    }

    set sessionExpired(value: Date) {
        this._sessionExpires = value
    }

    get permissions(): string[] {
        return this._permissions;
    }

    toDTO(): LoggedUserDTO {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            sessionExpires: this.sessionExpires,
            language: this.language,
            clinics: this.clinics,
            permissions: this.permissions,
        }
    }
}
