import { Container, IInit } from '../../../common/container/Container'
import { Observable } from 'rxjs'
import { Project, ProjectQuery, ProjectDTO, ProjectAnalysis } from '../models/Project'
import { ItemList } from '../../../common/models/ItemList'
import { Query } from '../../../common/api/Query'
import { IProjectApi } from '../api/ProjectApi'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { File } from 'modules/files/models/File'

type Props = {
  apiKey: symbol
}

export interface IProjectService extends IInit {
  getByID(id: string): Observable<Project | undefined>

  getByUserID( q: Query<ProjectQuery>): Observable<ItemList<Project>>

  getFilteredList(q: Query<ProjectQuery>): Observable<ItemList<Project>>

  add(e: Project): Observable<Project | undefined>

  update(e: ProjectDTO): Observable<Project | undefined>

  getAllProjects(): Observable<ItemList<Project>>

  delete(id: string): Observable<boolean>

  downloadResults(projectID: string): Observable<File | undefined>

  getFilteredListAnalysis(q: Query<ProjectQuery>): Observable<ItemList<ProjectAnalysis>>
}

export class ProjectService implements IProjectService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IProjectApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IProjectApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: ProjectDTO): Observable<Project | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Project | undefined> {
    return this._api.getByID(id)
  }

  getByUserID( q: Query<ProjectQuery>): Observable<ItemList<Project>> {
    return this._api.getByUserID(q)
  }

  getFilteredList(q: Query<ProjectQuery>): Observable<ItemList<Project>> {
    return this._api.getFilteredList(q)
  }

  getFilteredListAnalysis(q: Query<ProjectQuery>): Observable<ItemList<ProjectAnalysis>> {
    return this._api.getFilteredListAnalysis(q)
  }

  getAllProjects(): Observable<ItemList<Project>> {
    return this._api.getAllProjects()
  }

  update(e: ProjectDTO): Observable<Project | undefined> {
    return this._api.update(e)
  }

  downloadResults(id: string): Observable<File | undefined> {
    return this._api.downloadResults(id)
  }
}
