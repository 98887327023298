export type ClinicProps = {
  id?: string
  idClinic?: string
  name: string
  address: string
  cp: string
  locality: string
  cif: string
  odooNumber?: string
  email: string
  phone: string
  patients: string[]
  entryDate: Date
  usedCredits: number
  remainingCredits: number
  kitCode: string
  header: string
  signature: string
  roleSignature: string
  signedBy: string
  activeReceptiveness: boolean
  activePgd: boolean
  activePgsec: boolean
}

export class Clinic {
  private readonly _id: string | undefined
  private _idClinic: string | undefined
  private _name: string
  private _address: string
  private _cp: string
  private _locality: string
  private _cif: string
  private _odooNumber: string | undefined
  private _email: string
  private _phone: string
  private _patients: string[]
  private readonly _entryDate: Date
  private _usedCredits: number
  private _remainingCredits: number
  private _kitCode: string
  private _header: string
  private _signature: string
  private _roleSignature: string
  private _signedBy: string
  private _activeReceptiveness: boolean
  private _activePgd: boolean
  private _activePgsec: boolean

  constructor(p: ClinicProps) {
    this._id = p.id
    this._idClinic = p.idClinic
    this._name = p.name
    this._address = p.address
    this._cp = p.cp
    this._locality = p.locality
    this._cif = p.cif
    this._odooNumber = p.odooNumber
    this._email = p.email
    this._phone = p.phone
    this._patients = p.patients
    this._entryDate = new Date()
    this._usedCredits = p.usedCredits
    this._remainingCredits = p.remainingCredits
    this._kitCode = p.kitCode
    this._header = p.header
    this._signature = p.signature
    this._roleSignature = p.roleSignature
    this._signedBy = p.signedBy
    this._activeReceptiveness = p.activeReceptiveness
    this._activePgd = p.activePgd
    this._activePgsec = p.activePgsec
  }

  get id(): string | undefined {
    return this._id
  }

  get idClinic(): string | undefined {
    return this._idClinic
  }

  set idClinic(value: string | undefined) {
    this._idClinic = value
  }

  get name(): string {
    return this._name
  }

  set name(value: string) {
    this._name = value
  }

  get address(): string {
    return this._address
  }

  set address(value: string) {
    this._address = value
  }

  get cp(): string {
    return this._cp
  }

  set cp(value: string) {
    this._cp = value
  }

  get locality(): string {
    return this._locality
  }

  set locality(value: string) {
    this._locality = value
  }

  get activeReceptiveness(): boolean {
    return this._activeReceptiveness
  }

  get activePgsec(): boolean {
    return this._activePgsec
  }

  set activeReceptiveness(activeReceptiveness: boolean) {
    this._activeReceptiveness = activeReceptiveness
  }

  get activePgd(): boolean {
    return this._activePgd
  }

  set activePgd(activePgd: boolean) {
    this._activePgd = activePgd
  }

  get cif(): string {
    return this._cif
  }

  set cif(value: string) {
    this._cif = value
  }

  get odooNumber(): string | undefined {
    return this._odooNumber
  }

  set odooNumber(value: string | undefined) {
    this._odooNumber = value
  }

  get email(): string {
    return this._email
  }

  set email(value: string) {
    this._email = value
  }
  set activePgsec(activePgsec: boolean){
    this._activePgsec = activePgsec
  }

  get phone(): string {
    return this._phone
  }

  set phone(value: string) {
    this._phone = value
  }

  get patients(): string[] {
    return this._patients
  }

  set patients(value: string[]) {
    this._patients = value
  }

  get entryDate() : Date {
    return this._entryDate
  }

  get usedCredits() : number{
    return this._usedCredits
  }

  set usedCredits(value:number){
    this._usedCredits = value
  }

  get remainingCredits() : number{
    return this._remainingCredits
  }

  set remainingCredits(value :number) {
    this._remainingCredits = value
  }

  get kitCode() : string {
    return this._kitCode
  }

  set kitCode(value: string){
    this._kitCode = value
  }

  get header(): string{
    return this._header
  }

  get signature(): string{
    return this._signature
  }

  get roleSignature(): string{
    return this._roleSignature
  }

  get signedBy(): string{
    return this._signedBy
  }

}

export interface ClinicQuery {
  ids: string
  patient: string
  name: string
  cif: string
}
