import React, { useEffect, useState } from 'react'
import { RouteProps } from '../../routes/AppRouter'
import { ProjectsFormPg } from '../../features/projects'
import { Title } from '../../components/common/Title'
import { useTranslation } from 'react-i18next'
import { useParams } from '@reach/router'
import { PROJECT_SERVICE_KEY } from '../../modules/projects'
import { Project } from 'modules/projects/models/Project'
import { getProjectContainer } from 'container/project-module'
import { ProjectService } from 'modules/projects/services/ProjectService'

const projectService = getProjectContainer().get<ProjectService>(PROJECT_SERVICE_KEY)

export const FormPg = (props: RouteProps) => {
  const { t } = useTranslation()
  const params = useParams()
  const [project, setProject] = useState<Project>()
  const title = params && params.id === 'create' ? t('newProject') : t('Edit')

  useEffect(() => {
    if (params.id === 'create') {
      return
    }
    projectService.getByID(params.id).subscribe((value) => setProject(value))
  }, [params.id])

  return (
    <>
      <Title color={props.color} title={title || ''} />
      <ProjectsFormPg project={project} patientID={params.patientID} />
    </>
  )
}
