import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import SideBarMenuItem from './SideBarMenuItem'
import { sideBarMenuItems } from './sidebar-content'
import { Query, QueryParam } from '../../common/api/Query'
import { ClinicQuery } from '../../modules/clinics/models/Clinic'
import { getClinicContainer } from '../../container/clinic-modules'
import { ClinicService } from '../../modules/clinics/services/ClinicService'
import { CLINIC_SERVICE_KEY } from '../../modules/clinics'
import { getAuthContainer } from '../../container/auth-modules'
import { IAuthService } from '../../modules/auth/services/AuthService'
import { AUTH_SERVICE_KEY } from '../../modules/auth'
import { Permission } from '../../common/enums/Permissions'

const clinicService = getClinicContainer().get<ClinicService>(CLINIC_SERVICE_KEY)
const authService = getAuthContainer().get<IAuthService>(AUTH_SERVICE_KEY)

export const SideBarMenu: React.FC = () => {
  const classes = useStyles()

  const [receptiveness, setReceptiveness] = useState<boolean>(true)
  const [pdg, setPdg] = useState<boolean>(true)
  const [pgsec, setPgsec] = useState<boolean>(true)


  useEffect(() => {
      if (authService.get().permissions.includes(Permission.viewReceptiveness)) {
        return
      }
      const query: QueryParam<ClinicQuery>[] = []
      if (!authService.get().permissions.includes(Permission.viewAll)) {
        query.push(new QueryParam<ClinicQuery>('ids', authService.get().clinics))
      }
      clinicService
        .getFilteredList(
          new Query({
            pager: { offset: 0, limit: authService.get().clinics.length },
            query,
            sort: [{ field: 'name' }],
          }),
        ).subscribe((res) => {
          
          setReceptiveness(res.items.some((cl) => cl.activeReceptiveness));
          setPdg(res.items.some((cl) => cl.activePgd));
          setPgsec(res.items.some((cl) => cl.activePgsec));
        });
    }, [])
   

  return (
    <List component='nav' className={classes.sideBarMenu} disablePadding>
      {sideBarMenuItems(receptiveness, pdg, pgsec).map(
        (item, index) => item.userAccess && <SideBarMenuItem {...item} key={index} />,
      )}
    </List>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    sideBarMenu: {
      width: '100%',
      marginTop: theme.spacing(6),
    },
  }),
)

export default SideBarMenu
